import { Button } from '@/components/Button';
import DropdownMenu from '@/components/DropdownMenu';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { useDOMElement } from 'components/hooks/useDOMElement';
import { useTileContext } from 'contexts/TileContext';
import useCopyToClipboard from 'lib/useCopyToClipboard';
import { useState } from 'react';
import ClipboardToast, { toastIds } from 'ui/notifications/ClipboardToast';
import { DataStreamTileEditorCodeModal } from './DataStreamTileEditorCodeModal';

interface DataStreamTileEditorTileOptionsProps {
    tileConfig: DataStreamBaseTileConfig;
}

/**
 * Contains JSON Editor, for changes to be applied to the tile it is designated behavior to save on the modal,
 * and save then again on the tile
 * @returns Tile Editor options dropdown menu
 */
export const DataStreamTileEditorTileOptions: React.FC<DataStreamTileEditorTileOptionsProps> = ({ tileConfig }) => {
    const { tileId } = useTileContext();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const menuContainer = useDOMElement('dialogContent');

    // Wire up the copy tile ID actions
    const { copy } = useCopyToClipboard({
        onNotSupported: () => {
            // Log to console when clipboard is not available
            // eslint-disable-next-line no-console
            console.log(`Tile ID: ${tileId}`);
            ClipboardToast(`Clipboard not available, tile ID printed to console (${tileId})`, false, toastIds.SUCCESS);
        },
        onSuccess: () => {
            ClipboardToast('Tile ID copied to clipboard', false, toastIds.SUCCESS);
        },
        onError: () => {
            ClipboardToast('Tile ID could not be copied to clipboard', true, toastIds.ERROR);
        }
    });

    /**
     * Object list to map through to show each tile editor option
     */
    const tileEditorOptions = [
        {
            name: 'Edit JSON',
            testId: 'editJsonBtn',
            onClick: () => setIsModalOpen(true)
        },
        {
            name: 'Copy tile ID',
            testId: 'copyTileId',
            onClick: () => copy(tileId || 'Unknown')
        }
    ];

    return (
        <>
            <DropdownMenu>
                <DropdownMenu.Trigger
                    data-testid='DataStreamTileEditorTileOptionsButton'
                    className='data-[state=open]:bg-secondaryButtonBackgroundHover'
                >
                    <Button
                        variant='secondary'
                        icon={<FontAwesomeIcon icon={faEllipsisVertical} className='flex items-center text-xl' />}
                    />
                </DropdownMenu.Trigger>

                <DropdownMenu.Menu data-theme='dark' container={menuContainer}>
                    <DropdownMenu.Group>
                        {tileEditorOptions.map((option) => (
                            <div key={option.name}>
                                <DropdownMenu.Item onSelect={option.onClick} data-testid={option.testId}>
                                    {option.name}
                                </DropdownMenu.Item>
                            </div>
                        ))}
                    </DropdownMenu.Group>
                </DropdownMenu.Menu>
            </DropdownMenu>

            {isModalOpen && <DataStreamTileEditorCodeModal tileConfig={tileConfig} setIsModalOpen={setIsModalOpen} />}
        </>
    );
};
