import { cn } from '@/lib/cn';
import Tooltip, { type TooltipProps } from 'components/tooltip/Tooltip';
import useOverflowing from 'lib/useOverflowing';

interface InlineTextEditOverflowWrapperProps {
    value: string;
    placeholder: string;
    className?: string;
    tooltipProps?: Partial<TooltipProps>;
}

/**
 * Wrapper for InlineTextEdit displayComponent content
 *
 * Example usage displayComponent={<InlineTextEditOverflowWrapper value={title} />}
 */
export const InlineTextEditOverflowWrapper = ({
    value,
    placeholder,
    className,
    tooltipProps
}: InlineTextEditOverflowWrapperProps) => {
    const { ref, isOverflowing } = useOverflowing<HTMLDivElement>();

    // Trim the value to prevent empty strings being rendered and un-clickable.
    const trimmedValue = value?.trim();

    return (
        <Tooltip title={value} disabled={!isOverflowing} asChild {...tooltipProps}>
            <span ref={ref} className={cn('block truncate', className)}>
                {trimmedValue || placeholder}
            </span>
        </Tooltip>
    );
};
