import { InlineTextEditOnBlur, InlineTextEditOnBlurProps } from './InlineTextEditOnBlur';
import { InlineTextEditOnChange, InlineTextEditOnChangeProps } from './InlineTextEditOnChange';
import {
    InlineTextEditOnDebouncedChange,
    InlineTextEditOnDebouncedChangeProps
} from './InlineTextEditOnDebouncedChange';

type InlineTextEditProps = (
    | InlineTextEditOnChangeProps
    | InlineTextEditOnDebouncedChangeProps
    | InlineTextEditOnBlurProps
) & {
    changeTrigger: 'keyDown' | 'debouncedKeyDown' | 'blur';
};

/**
 * A component for inline text editing
 *
 * The `changeTrigger` prop controls how the text changes are propagated to the parent
 */
export const InlineTextEdit = ({ changeTrigger, ...props }: InlineTextEditProps) => {
    if (changeTrigger === 'blur') return <InlineTextEditOnBlur {...props} />;
    if (changeTrigger === 'debouncedKeyDown' && 'debounceTime' in props)
        return <InlineTextEditOnDebouncedChange {...props} />;
    return <InlineTextEditOnChange {...props} />;
};
