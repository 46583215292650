import { useState } from 'react';
import { InlineTextEditCommonProps, InlineTextEditOnChange } from './InlineTextEditOnChange';

export type InlineTextEditOnBlurProps = InlineTextEditCommonProps;

export const InlineTextEditOnBlur = (props: InlineTextEditOnBlurProps) => {
    // Use key to reset the state of InlineTextValueHolder once the value is saved
    return <InlineTextValueHolder key={props.value} {...props} />;
};

const InlineTextValueHolder = ({
    value,
    validate,
    onChange,
    onBlur,
    onCancel,
    ...props
}: InlineTextEditOnBlurProps) => {
    const [editValue, setEditValue] = useState(value);

    const handleBlur = () => {
        if (validate ? validate(editValue) : true) {
            onChange(editValue);
        }
        onBlur?.();
    };

    return (
        <InlineTextEditOnChange
            value={editValue}
            onChange={(v) => setEditValue(v)}
            {...props}
            onBlur={handleBlur}
            onCancel={onCancel}
        />
    );
};
