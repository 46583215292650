import { cn } from '@/lib/cn';
import Tooltip, { type TooltipProps } from 'components/tooltip/Tooltip';
import useOverflowing from 'lib/useOverflowing';
import { FC } from 'react';

interface EasyEditOverflowWrapperProps {
    value: string;
    placeholder: string;
    className?: string;
    tooltipProps?: Partial<TooltipProps>;
}

/**
 * @deprecated
 * Use `<InlineTextEditOverflowWrapper />` instead
 * 
 * Wrapper for the EasyEdit component
 *
 * Example ussage displayComponent={<OverflowWrapper value={title} />}
 */
const EasyEditOverflowWrapper: FC<EasyEditOverflowWrapperProps> = ({ value, placeholder, className, tooltipProps }) => {
    const { ref, isOverflowing } = useOverflowing<HTMLDivElement>();

    // Trim the value to prevent empty strings being rendered and un-clickable.
    const trimmedValue = value?.trim();

    return (
        <Tooltip title={value} disabled={!isOverflowing} {...tooltipProps}>
            <div ref={ref} className={cn('truncate', className)}>
                {trimmedValue || placeholder}
            </div>
        </Tooltip>
    );
};

export default EasyEditOverflowWrapper;
