import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';
import { InlineTextEditCommonProps, InlineTextEditOnChange } from './InlineTextEditOnChange';

export type InlineTextEditOnDebouncedChangeProps = InlineTextEditCommonProps & {
    debounceTime: number;
};

export const InlineTextEditOnDebouncedChange = ({
    value,
    debounceTime,
    onChange,
    onCancel,
    onBlur,
    ...props
}: InlineTextEditOnDebouncedChangeProps) => {
    const [editValue, setEditValue] = useState(value);
    const debouncedOnChange = useMemo(() => debounce(onChange, debounceTime), [onChange, debounceTime]);

    // Ensure we dont try to set state when unmounted
    useEffect(() => {
        return () => debouncedOnChange.cancel();
    }, [debouncedOnChange]);

    // If the value changes from an external source, update our edited value
    useEffect(() => {
        setEditValue(value);
    }, [value]);

    const handleChange = (v: string) => {
        setEditValue(v);
        debouncedOnChange(v);
    };

    const handleCancel = (originalValue: string) => {
        debouncedOnChange.cancel();
        if (value !== originalValue) {
            onChange(originalValue);
        }
        onCancel?.(originalValue);
    };

    const handleBlur = () => {
        if (editValue !== value) {
            debouncedOnChange.cancel();
            onChange(editValue);
        }
        onBlur?.();
    };

    return (
        <InlineTextEditOnChange
            value={editValue}
            onChange={handleChange}
            onBlur={handleBlur}
            onCancel={handleCancel}
            {...props}
        />
    );
};
