import { cn } from '@/lib/cn';
import { ChangeEventHandler, KeyboardEventHandler, useRef, useState } from 'react';

export type InlineTextEditCommonProps = {
    value: string;
    placeholder?: string;
    displayComponent?: React.ReactNode;
    allowEditing?: boolean;
    inputClassName?: string;
    containerClassName?: string;
    onChange: (value: string) => void;
    onBlur?: () => void;
    validate?: (value: string) => void;
    onCancel?: (originalValue: string) => void;
    onFocus?: () => void;
};

export type InlineTextEditOnChangeProps = InlineTextEditCommonProps;

export const InlineTextEditOnChange = ({
    value,
    inputClassName,
    containerClassName,
    placeholder,
    displayComponent,
    allowEditing = true,
    onChange,
    onBlur,
    onCancel,
    onFocus
}: InlineTextEditOnChangeProps) => {
    const [originalValue, setOriginalValue] = useState(value);
    const [isEditing, setIsEditing] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleBlur = () => {
        setIsEditing(false);
        setOriginalValue(value);
        onBlur?.();
    };

    const handleCancel = () => {
        setIsEditing(false);
        if (value !== originalValue) {
            onChange(originalValue);
        }
        onCancel?.(originalValue);
    };

    const handleFocus = () => {
        setIsEditing(true);
        onFocus?.();
    };

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (e.key === 'Escape') {
            handleCancel();
        } else if (e.key === 'Enter') {
            handleBlur();
        }
    };

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const newValue = e.target.value;
        onChange(newValue);
    };

    const handleDisplayFocus = () => {
        if (allowEditing) {
            setIsEditing(true);
        }
    };

    if (!isEditing) {
        return (
            <span
                className={cn(containerClassName, {
                    'block hover:cursor-pointer hover:italic': allowEditing && !isEditing
                })}
                onClick={handleDisplayFocus}
                onFocus={handleDisplayFocus}
                tabIndex={allowEditing ? 0 : undefined}
                role={allowEditing ? 'textbox' : undefined}
            >
                {displayComponent || value || placeholder}
            </span>
        );
    }

    return (
        <span
            className={cn(containerClassName, {
                'block hover:cursor-pointer hover:italic': !isEditing
            })}
        >
            <input
                ref={inputRef}
                autoFocus
                className={cn(
                    'w-full p-0 m-0 text-[length:inherit] leading-[length:inherit] bg-transparent border-none focus:outline-none focus:ring-0',
                    inputClassName
                )}
                value={value}
                placeholder={placeholder}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
            />
        </span>
    );
};
